import axios from 'axios';
import { globalWebSocketManager } from '../socket';

// Function to get the token from localStorage
const getToken = () => localStorage.getItem('token');

// Function to get the refresh token from localStorage
const getRefreshToken = () => localStorage.getItem('refreshToken');

// Function to save new tokens to localStorage
const saveToken = (tokens) => {
  const { access_token, refresh_token } = tokens;
  localStorage.setItem('token', access_token);
  localStorage.setItem('refreshToken', refresh_token);
};

// Function to handle logout
const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('currentQuest');
  localStorage.removeItem('questState');

  // Закрываем WebSocket соединение при выходе
  if (globalWebSocketManager) {
    globalWebSocketManager.disconnect();
  }

  window.location.href = '/login';
};

// Function to refresh the token
const refreshToken = async () => {
  try {
    const existingRefreshToken = getRefreshToken();
    if (!existingRefreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axios.post(
      '/api/account/refresh-token',
      {
        refresh_token: existingRefreshToken,
      }
    );
    const tokens = response.data;
    saveToken(tokens);
    return tokens.access_token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    logout();
    return null;
  }
};

// Create an axios instance
const api = axios.create({
  baseURL: '/api',
  timeout: 15000,
  retryDelay: 1000,
  maxRetries: 3
});

// Request interceptor to include Authorization header
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // Add request ID for tracking
    config.headers['X-Request-ID'] = Math.random().toString(36).substring(7);
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors and refresh token
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.config) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    originalRequest.retryCount = originalRequest.retryCount || 0;

    // Обработка 401 ошибки
    if (error.response?.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const newToken = await refreshToken();

        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        logout();
        return Promise.reject(refreshError);
      }
    }

    // Retry logic for other errors
    if (originalRequest.retryCount < (api.defaults.maxRetries || 3)) {
      originalRequest.retryCount++;

      return new Promise(resolve => {
        setTimeout(() => {
          resolve(api(originalRequest));
        }, api.defaults.retryDelay || 1000);
      });
    }

    return Promise.reject(error);
  }
);

// API endpoints
const apiEndpoints = {
  // Auth endpoints
  login: (data) => api.post('/account/login', data),
  register: (data) => api.post('/account/register', data),
  refreshToken: (data) => api.post('/account/refresh-token', data),
  logout: () => {
    logout();
    return Promise.resolve();
  },

  // Quest endpoints
  getQuests: () => api.get('/quests'),
  getQuest: (id) => api.get(`/quests/${id}`),
  getQuestState: (id) => api.get(`/quest-state/${id}`),
  updateQuestState: async (id, data) => {
    try {
      const response = await api.post(`/quest-state/${id}`, {
        current_step: data.currentStep || data.current_step,
        current_substep: data.currentSubStep || data.current_substep,
        points: data.points || 100,
        role: data.role || '',
        is_team: data.isTeam || false,
        team_id: data.teamId || null,
        incorrect_attempts: data.incorrectAttempts || data.incorrect_attempts || 0,
        used_hints: data.usedHints || data.used_hints || [],
        completed_steps: data.completedSteps || data.completed_steps || [],
        status: data.status || 'in_progress'
      });

      // Отправляем обновление через WebSocket
      if (globalWebSocketManager && globalWebSocketManager.isConnected()) {
        globalWebSocketManager.send({
          type: 'state_update',
          data: {
            ...data,
            questId: id,
            timestamp: new Date().toISOString()
          }
        });
      }

      return response.data;
    } catch (error) {
      console.error('Error updating quest state:', error);
      throw error;
    }
  },
  resetQuestState: (id) => api.post(`/quest-state/${id}/reset`),
  getQuestProgress: (id) => api.get(`/quest-progress/${id}`),
  completeQuest: (id, data) => api.post(`/quest/${id}/complete`, data),

  // Team endpoints
  createTeam: (data) => api.post('/team/create', data),
  validateTeamCode: (code) => api.post('/team/validate-code', { code }),
  joinTeamByCode: (code, questId) => api.post('/team/join-by-code', { code, quest_id: questId }),
  startTeamQuest: (teamId) => api.post(`/team/${teamId}/start`),
  getTeamStatus: (teamId) => api.get(`/team/status/${teamId}`),
  getTeamMembers: (teamId) => api.get(`/team/members/${teamId}`),
  updateTeamRole: (teamId, data) => api.post(`/team/${teamId}/update-role`, data),
  updateTeamState: async (teamId, data) => {
    try {
      const response = await api.post(`/team/status/${teamId}`, {
        current_step: data.currentStep || data.current_step,
        current_substep: data.currentSubStep || data.current_substep,
        points: data.points,
        role: data.role,
        incorrect_attempts: data.incorrectAttempts || data.incorrect_attempts,
        used_hints: data.usedHints || data.used_hints,
        completed_steps: data.completedSteps || data.completed_steps,
        status: 'in_progress'
      });

      // Отправляем обновление через WebSocket
      if (globalWebSocketManager && globalWebSocketManager.isConnected()) {
        globalWebSocketManager.send({
          type: 'team_state_update',
          data: {
            ...data,
            teamId,
            timestamp: new Date().toISOString()
          }
        });
      }

      return response.data;
    } catch (error) {
      console.error('Error updating team state:', error);
      throw error;
    }
  },

  // Payment endpoints
  initiatePayment: (data) => api.post('/account/pay', data),
  completePayment: (data) => api.post('/account/complete-payment', data),
  getPaymentStatus: (paymentId) => api.get(`/account/payment-status/${paymentId}`),

  // Account endpoints
  getProfile: () => api.get('/account/profile'),
  updateProfile: (data) => api.put('/account/profile', data),
  getQuestHistory: () => api.get('/account/quest-history'),
  getTransactionHistory: () => api.get('/account/transaction-history'),
  getPurchasedQuests: () => api.get('/account/purchased-quests'),
  getQuestStatus: (id) => api.get(`/account/quest-status/${id}`),

  // Image Upload endpoint
  uploadImage: (formData, questId, pointIndex, stepIndex) => {
    return api.post('/upload-image/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        quest_id: questId,
        point_index: pointIndex,
        step_index: stepIndex,
      },
      timeout: 30000,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log('Upload progress:', percentCompleted);
      }
    });
  },

  // Error tracking endpoint
  reportError: (error) =>
    api.post('/error-report', {
      error: {
        message: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
        url: window.location.href
      },
    }),

  // Local storage helpers
  clearQuestState: () => {
    localStorage.removeItem('currentQuest');
    localStorage.removeItem('questState');
  },

  clearTeamState: (questId) => {
    localStorage.removeItem(`questMode_${questId}`);
    localStorage.removeItem(`teamCode_${questId}`);
  },

  saveQuestMode: (questId, modeData) => {
    localStorage.setItem(`questMode_${questId}`, JSON.stringify(modeData));
  },

  getQuestMode: (questId) => {
    try {
      const data = localStorage.getItem(`questMode_${questId}`);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error parsing quest mode data:', error);
      return null;
    }
  },

  // WebSocket helpers
  reconnectWebSocket: async (id, isTeam = false) => {
    if (globalWebSocketManager) {
      try {
        await globalWebSocketManager.connect(id, isTeam);
        return true;
      } catch (error) {
        console.error('Error reconnecting WebSocket:', error);
        return false;
      }
    }
    return false;
  }
};

// Add API endpoints to the API instance
Object.assign(api, apiEndpoints);

export default api;
export { saveToken, logout, refreshToken, getToken, getRefreshToken };

