import React, {
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // <-- добавлен useParams
import PropTypes from 'prop-types';
import { useQuestState } from './QuestStateProvider';
import useSocket from '../hooks/useSocket';
import SpeechStage from './SpeechStage';
import RiddleStage from './RiddleStage';
import ImageStage from './ImageStage';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';
import api from '../api';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Progress } from './ui/progress';
import { Shield, Crown, Users, Award } from 'lucide-react';

const QuestContent = ({ quest }) => {
  const navigate = useNavigate();
  const { step: routeStep, substep: routeSubStep } = useParams(); // <-- получаем параметры маршрута
  const {
    state: {
      questId,
      currentStep,
      currentSubStep,
      points,
      role,
      isTeam,
      teamId,
      teamMembers
    },
    updateState,
    isProcessing
  } = useQuestState();

  const { isConnected, error: socketError, sendMessage } = useSocket(
    isTeam ? teamId : questId,
    isTeam
  );

  const [inputText, setInputText] = useState('');
  const [image, setImage] = useState(null);
  const [resultMessage, setResultMessage] = useState('');
  const [localError, setLocalError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const progressionLockRef = useRef(false);
  const navigationLockRef = useRef(false);

  // Вычисляем прогресс квеста
  const totalSteps = quest.points.reduce((total, point) => total + point.steps.length, 0);
  const currentTotalStep = quest.points.slice(0, currentStep - 1).reduce(
    (total, point) => total + point.steps.length, 0
  ) + currentSubStep + 1;
  const progress = (currentTotalStep / totalSteps) * 100;

  const getCurrentStage = useCallback(() => {
    if (!quest?.points?.[currentStep - 1]?.steps?.[currentSubStep]) {
      return null;
    }
    return quest.points[currentStep - 1].steps[currentSubStep];
  }, [quest, currentStep, currentSubStep]);

  const safeNavigate = useCallback(async (path) => {
    if (navigationLockRef.current) return;
    navigationLockRef.current = true;
    try {
      await new Promise(resolve => setTimeout(resolve, 100));
      navigate(path, { replace: true });
    } finally {
      setTimeout(() => {
        navigationLockRef.current = false;
      }, 500);
    }
  }, [navigate]);

  // <-- Новый useEffect для автоматической навигации в командном режиме
  useEffect(() => {
    if (isTeam && questId) {
      const currentRouteStep = parseInt(routeStep, 10) || 1;
      const currentRouteSubStep = parseInt(routeSubStep, 10) || 0;
      if (currentRouteStep !== currentStep || currentRouteSubStep !== currentSubStep) {
        safeNavigate(`/quest/${questId}/step/${currentStep}/substep/${currentSubStep}`);
      }
    }
  }, [currentStep, currentSubStep, isTeam, questId, routeStep, routeSubStep, safeNavigate]);
  // -----------------------------------------------

  const handleStepProgression = useCallback(async () => {
    if (progressionLockRef.current || isProcessing || !isConnected) {
      console.log('Progression blocked:', {
        locked: progressionLockRef.current,
        processing: isProcessing,
        connected: isConnected
      });
      return;
    }
    progressionLockRef.current = true;
    setIsLoading(true);
    try {
      const currentPoint = quest.points[currentStep - 1];
      if (!currentPoint) {
        throw new Error('Invalid current point');
      }
      let nextStep = currentStep;
      let nextSubStep = currentSubStep;
      // Проверяем завершение квеста
      if (currentSubStep + 1 >= currentPoint.steps.length) {
        if (currentStep >= quest.points.length) {
          const finalState = {
            is_completed: true,
            completed_at: new Date().toISOString(),
            points: points
          };
          if (isTeam) {
            await api.post(`/team/${teamId}/complete`, finalState);
          } else {
            await updateState(finalState);
          }
          await safeNavigate(`/quest/${questId}/complete`);
          return;
        }
        nextStep = currentStep + 1;
        nextSubStep = 0;
      } else {
        nextSubStep = currentSubStep + 1;
      }
      const newState = {
        currentStep: nextStep,
        currentSubStep: nextSubStep,
        points: points,
        completedSteps: nextStep > currentStep 
          ? [...(quest.completedSteps || []), currentStep]
          : quest.completedSteps || []
      };
      // Отправляем обновление через WebSocket
      sendMessage({
        type: 'state_update',
        data: {
          ...newState,
          teamId: isTeam ? teamId : undefined,
          questId
        }
      });
      // Обновляем состояние
      await updateState(newState);
      // Навигация к следующему этапу
      await safeNavigate(
        `/quest/${questId}/step/${nextStep}/substep/${nextSubStep}`
      );
    } catch (error) {
      console.error('Error in progression:', error);
      setLocalError('Failed to progress to next step');
    } finally {
      progressionLockRef.current = false;
      setIsLoading(false);
    }
  }, [
    quest,
    currentStep,
    currentSubStep,
    questId,
    isProcessing,
    isConnected,
    isTeam,
    teamId,
    points,
    updateState,
    sendMessage,
    safeNavigate
  ]);

  const handleTextSubmit = async (event) => {
    event.preventDefault();
    if (isProcessing || !inputText.trim() || !isConnected) return;
    const currentStage = getCurrentStage();
    if (!currentStage?.correctAnswer) return;
    setIsLoading(true);
    try {
      const correctAnswers = currentStage.correctAnswer
        .split('|')
        .map(answer => answer.trim().toLowerCase());
      const userAnswer = inputText.trim().toLowerCase();
      const isCorrect = correctAnswers.includes(userAnswer);
      if (isCorrect) {
        setResultMessage('Correct!');
        setInputText('');
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await updateState({
          points: Math.max(0, points - 5),
          incorrectAttempts: (quest.incorrectAttempts || 0) + 1
        });
        setResultMessage(
          `Incorrect answer. Please try again! Points remaining: ${result?.points || points}`
        );
      }
    } catch (error) {
      console.error('Error processing answer:', error);
      setLocalError('Error checking answer');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();
    if (isProcessing || !image || !isConnected) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('quest_id', questId);
    formData.append('point_index', currentStep - 1);
    formData.append('step_index', currentSubStep);
    try {
      const response = await api.uploadImage(formData, questId, currentStep - 1, currentSubStep);
      const similarity = response.data.similarity || 0;
      const SIMILARITY_THRESHOLD = 25.0;
      if (similarity >= SIMILARITY_THRESHOLD) {
        setImage(null);
        setResultMessage('Image matches the template!');
        await new Promise(resolve => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await updateState({
          points: Math.max(0, points - 5),
          incorrectAttempts: (quest.incorrectAttempts || 0) + 1
        });
        setResultMessage(
          `Image doesn't match. Required similarity: ${SIMILARITY_THRESHOLD}%, got: ${similarity.toFixed(
            2
          )}%. Points: ${result?.points || points}`
        );
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setLocalError(error.response?.data?.message || 'Error uploading image');
    } finally {
      setIsLoading(false);
    }
  };

  const handleHint = async (hintId) => {
    if (!hintId || isProcessing || !isConnected) return;
    try {
      const result = await updateState({
        points: Math.max(0, points - 10),
        usedHints: [...(quest.usedHints || []), hintId]
      });
      if (result) {
        setResultMessage(`Hint applied. Points remaining: ${result.points}`);
      }
    } catch (error) {
      console.error('Error applying hint:', error);
      setLocalError('Error showing hint');
    }
  };

  // Сброс сообщения результата при смене этапа
  useEffect(() => {
    setResultMessage('');
  }, [currentStep, currentSubStep]);

  // Обработка ошибок и состояния загрузки
  if (!isConnected) {
    return (
      <ErrorMessage
        message="Lost connection to server. Reconnecting..."
        critical={false}
      />
    );
  }
  if (socketError) {
    return (
      <ErrorMessage
        message={`Connection error: ${socketError.message}`}
        critical={true}
      />
    );
  }
  if (isLoading || isProcessing) {
    return <LoadingSpinner message="Processing..." />;
  }
  const currentStage = getCurrentStage();
  if (!currentStage) {
    return <ErrorMessage message="Invalid quest stage" />;
  }
  return (
    <div className="quest-content">
      <Card className="mb-6">
        <CardHeader className="pb-2">
          <CardTitle className="flex items-center justify-between">
            <h1>{quest.name}</h1>
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              {isTeam ? (
                <>
                  <Users className="h-4 w-4" />
                  <span>Team Quest</span>
                  <Shield className="h-4 w-4" />
                  <span>{role || 'No Role'}</span>
                </>
              ) : (
                <>
                  <Shield className="h-4 w-4" />
                  <span>Solo Quest</span>
                </>
              )}
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {/* Team info section */}
          {isTeam && teamMembers && (
            <div className="mb-4 p-4 bg-background/50 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <Users className="h-5 w-5 text-primary" />
                  <h3 className="font-medium">Team Members</h3>
                </div>
                <Award className="h-5 w-5 text-yellow-500" />
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {teamMembers.map((member) => (
                  <div key={member.userId} className="flex items-center gap-2 text-sm">
                    {member.isLeader && <Crown className="h-4 w-4 text-yellow-500" />}
                    <span>{member.username}</span>
                    <Shield className="h-4 w-4 text-gray-400" />
                    <span className="text-xs text-gray-500">{member.role}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Progress section */}
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>
                Step {currentStep} of {quest.points.length}
              </span>
              <span>Points: {points}</span>
            </div>
            <Progress value={progress} />
          </div>
        </CardContent>
      </Card>
      {/* Error message */}
      {localError && (
        <ErrorMessage
          message={localError}
          retryAction={() => setLocalError(null)}
          timeout={5000}
        />
      )}
      {/* Stage content */}
      {(() => {
        const commonProps = {
          questId,
          character: quest.points[currentStep - 1].character,
          text: currentStage.text,
          audioFile: currentStage.audioFile,
          isTeam,
          teamId,
          role
        };
        switch (currentStage.type) {
          case 'speech':
            return (
              <SpeechStage
                {...commonProps}
                onNext={handleStepProgression}
              />
            );
          case 'riddle':
            return (
              <RiddleStage
                {...commonProps}
                inputText={inputText}
                onInputChange={(e) => setInputText(e.target.value)}
                onSubmit={handleTextSubmit}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                resultMessage={resultMessage}
                isProcessing={isLoading}
                hint={currentStage.hint}
              />
            );
          case 'image':
            return (
              <ImageStage
                {...commonProps}
                onFileChange={(e) => setImage(e.target.files[0])}
                onSubmit={handleImageSubmit}
                showHint={!!currentStage.hint && points > 10}
                onHint={() => handleHint(currentStage.id)}
                resultMessage={resultMessage}
                isProcessing={isLoading}
                hint={currentStage.hint}
              />
            );
          default:
            return <div>Unknown stage type: {currentStage.type}</div>;
        }
      })()}
    </div>
  );
};

QuestContent.propTypes = {
  quest: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        character: PropTypes.string.isRequired,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.oneOf(['speech', 'riddle', 'image']).isRequired,
            text: PropTypes.string.isRequired,
            correctAnswer: PropTypes.string,
            hint: PropTypes.string,
            audioFile: PropTypes.string,
            id: PropTypes.string,
          })
        ).isRequired,
      })
    ).isRequired,
  }),
};

export default QuestContent;

