import log from 'loglevel';

log.setLevel('DEBUG');

class WebSocketManager {
    constructor() {
        // Основные свойства
        this.socket = null;
        this.listeners = new Map();
        this.reconnectAttempts = 0;
        this.pendingMessages = [];
        this.connectionPromise = null;
        this.heartbeatInterval = null;
        this.currentId = null;
        this.isTeam = false;
        this.lastHeartbeatResponse = null;
        this.heartbeatTimeout = null;
        this.retryTimeout = null;
        this.connectionCheckInterval = null;

        // Константы
        this.MAX_RECONNECT_ATTEMPTS = 5;
        this.RECONNECT_DELAY = 2000;
        this.CONNECTION_TIMEOUT = 10000;
        this.HEARTBEAT_INTERVAL = 30000;
        this.HEARTBEAT_TIMEOUT = 5000;
        this.CONNECTION_CHECK_INTERVAL = 5000;
    }

    getWebSocketUrl(id, isTeam) {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const baseUrl = `${protocol}//${window.location.host}/ws`;
        return isTeam ? `${baseUrl}/team/${id}` : `${baseUrl}/${id}`;
    }

    async connect(id, isTeam = false) {
        if (this.connectionPromise) {
            return this.connectionPromise;
        }

        this.currentId = id;
        this.isTeam = isTeam;

        this.connectionPromise = new Promise((resolve, reject) => {
            try {
                if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
                    log.debug('Closing existing WebSocket connection');
                    this.socket.close();
                }

                const wsUrl = this.getWebSocketUrl(id, isTeam);
                log.debug(`Connecting to WebSocket: ${wsUrl}`);
                this.socket = new WebSocket(wsUrl);

                // Установка таймаута соединения
                const connectionTimeout = setTimeout(() => {
                    if (this.socket && this.socket.readyState !== WebSocket.OPEN) {
                        this.socket.close();
                        reject(new Error('WebSocket connection timeout'));
                    }
                }, this.CONNECTION_TIMEOUT);

                this.socket.onopen = () => {
                    clearTimeout(connectionTimeout);
                    this.reconnectAttempts = 0;
                    this.startHeartbeat();
                    this.startConnectionCheck();
                    this.processPendingMessages();
                    this.notifyListeners('connection_status', { connected: true });
                    log.info(`WebSocket connection established for ${isTeam ? 'team' : 'quest'} ${id}`);
                    resolve();
                };

                this.socket.onclose = async (event) => {
                    clearTimeout(connectionTimeout);
                    this.cleanup();
                    log.info('WebSocket connection closed', event);

                    if (!event.wasClean && this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
                        this.reconnectAttempts++;
                        const delay = this.RECONNECT_DELAY;
                        
                        log.info(
                            `Attempting to reconnect in ${delay}ms (${this.reconnectAttempts}/${this.MAX_RECONNECT_ATTEMPTS})`
                        );

                        if (this.retryTimeout) {
                            clearTimeout(this.retryTimeout);
                        }

                        this.retryTimeout = setTimeout(() => {
                            this.connect(this.currentId, this.isTeam).catch(err => {
                                log.error('Reconnection failed:', err);
                            });
                        }, delay);
                    }

                    this.notifyListeners('connection_status', { 
                        connected: false,
                        wasClean: event.wasClean,
                        code: event.code,
                        reason: event.reason
                    });
                };

                this.socket.onerror = (error) => {
                    log.error('WebSocket error:', error);
                    this.notifyListeners('connection_error', error);
                };

                this.socket.onmessage = (event) => {
                    try {
                        const message = JSON.parse(event.data);
                        log.debug('Received message:', message);

                        if (message.type === 'heartbeat') {
                            this.handleHeartbeat();
                            return;
                        }

                        if (message.type === 'heartbeat_ack') {
                            this.lastHeartbeatResponse = Date.now();
                            return;
                        }

                        this.notifyListeners(message.type, message.data);
                    } catch (error) {
                        log.error('Error processing message:', error);
                    }
                };

            } catch (error) {
                log.error('Error in connect:', error);
                reject(error);
            }
        });

        return this.connectionPromise;
    }

    cleanup() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }

        if (this.heartbeatTimeout) {
            clearTimeout(this.heartbeatTimeout);
            this.heartbeatTimeout = null;
        }

        if (this.connectionCheckInterval) {
            clearInterval(this.connectionCheckInterval);
            this.connectionCheckInterval = null;
        }

        if (this.retryTimeout) {
            clearTimeout(this.retryTimeout);
            this.retryTimeout = null;
        }

        this.socket = null;
        this.connectionPromise = null;
        this.lastHeartbeatResponse = null;
    }

    disconnect() {
        if (this.socket) {
            if (this.socket.readyState === WebSocket.OPEN) {
                this.send({
                    type: 'disconnect',
                    timestamp: new Date().toISOString()
                });
            }
            this.socket.close();
            this.cleanup();
        }
    }

    startHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }

        this.heartbeatInterval = setInterval(() => {
            if (this.socket?.readyState === WebSocket.OPEN) {
                this.send({
                    type: 'heartbeat',
                    timestamp: new Date().toISOString()
                });

                // Установка таймаута для ответа на heartbeat
                if (this.heartbeatTimeout) {
                    clearTimeout(this.heartbeatTimeout);
                }

                this.heartbeatTimeout = setTimeout(() => {
                    log.warn('Heartbeat response timeout');
                    if (this.socket) {
                        this.socket.close();
                    }
                }, this.HEARTBEAT_TIMEOUT);
            }
        }, this.HEARTBEAT_INTERVAL);
    }

    handleHeartbeat() {
        this.send({
            type: 'heartbeat_ack',
            timestamp: new Date().toISOString()
        });
    }

    startConnectionCheck() {
        if (this.connectionCheckInterval) {
            clearInterval(this.connectionCheckInterval);
        }

        this.connectionCheckInterval = setInterval(() => {
            if (this.lastHeartbeatResponse) {
                const timeSinceLastResponse = Date.now() - this.lastHeartbeatResponse;
                if (timeSinceLastResponse > this.HEARTBEAT_INTERVAL * 2) {
                    log.warn('Connection check failed - no recent heartbeat response');
                    if (this.socket) {
                        this.socket.close();
                    }
                }
            }
        }, this.CONNECTION_CHECK_INTERVAL);
    }

    send(message) {
        const messageString = JSON.stringify({
            ...message,
            timestamp: new Date().toISOString()
        });

        if (this.socket?.readyState === WebSocket.OPEN) {
            this.socket.send(messageString);
            log.debug('Message sent:', message);
        } else {
            log.debug('Queuing message:', message);
            this.pendingMessages.push(messageString);
        }
    }

    processPendingMessages() {
        while (this.pendingMessages.length > 0) {
            const message = this.pendingMessages.shift();
            if (this.socket?.readyState === WebSocket.OPEN) {
                this.socket.send(message);
            }
        }
    }

    addEventListener(event, callback) {
        if (!this.listeners.has(event)) {
            this.listeners.set(event, new Set());
        }
        this.listeners.get(event).add(callback);
        log.debug(`Added listener for event: ${event}`);

        // Сразу отправляем статус соединения для новых слушателей connection_status
        if (event === 'connection_status') {
            callback({ 
                connected: this.isConnected(),
                reconnectAttempts: this.reconnectAttempts
            });
        }
    }

    removeEventListener(event, callback) {
        if (this.listeners.has(event)) {
            this.listeners.get(event).delete(callback);
            if (this.listeners.get(event).size === 0) {
                this.listeners.delete(event);
            }
            log.debug(`Removed listener for event: ${event}`);
        }
    }

    notifyListeners(event, data) {
        const listeners = this.listeners.get(event);
        if (listeners) {
            listeners.forEach(callback => {
                try {
                    callback(data);
                } catch (error) {
                    log.error(`Error in listener for event ${event}:`, error);
                }
            });
        }
    }

    isConnected() {
        return this.socket && this.socket.readyState === WebSocket.OPEN;
    }

    getConnectionState() {
        if (!this.socket) return 'CLOSED';
        return ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'][this.socket.readyState];
    }

    getReconnectAttempts() {
        return this.reconnectAttempts;
    }

    resetReconnectAttempts() {
        this.reconnectAttempts = 0;
    }
}

export const globalWebSocketManager = new WebSocketManager();
