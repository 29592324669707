import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import {
    Users,
    Copy,
    CheckCircle,
    Loader2,
    AlertCircle,
    Crown,
    Shield,
    Clock,
    UserPlus,
    Volume2,
    Info
} from 'lucide-react';
import api from '../api';
import { globalWebSocketManager } from '../socket';

const TeamWaitingRoom = () => {
    const { teamId } = useParams();
    const navigate = useNavigate();
    const [team, setTeam] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [isUpdatingRole, setIsUpdatingRole] = useState(false);
    const [isConnecting, setIsConnecting] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [reconnectAttempt, setReconnectAttempt] = useState(0);

    const initializeTeam = useCallback(async () => {
        try {
            setIsConnecting(true);
            setError(null);

            // Загружаем данные команды и профиля пользователя
            const [teamResponse, profileResponse] = await Promise.all([
                api.get(`/team/status/${teamId}`),
                api.get('/account/profile')
            ]);

            // Если квест уже начат ("in_progress"), перенаправляем в квест
            if (teamResponse.data.status === 'in_progress') {
                navigate(`/quest/${teamResponse.data.quest_id}/step/1/substep/0`);
                return;
            }

            setTeam(teamResponse.data);
            setCurrentUser(profileResponse.data);

            // Инициализация WebSocket
            const handleConnectionStatus = (status) => {
                setIsConnected(status.connected);
                if (!status.connected) {
                    setError('Connection lost. Reconnecting...');
                    setReconnectAttempt(prev => prev + 1);
                } else {
                    setError(null);
                    setReconnectAttempt(0);
                }
            };

            // Подписываемся на событие обновления состояния команды – теперь ожидаем "team_state_update"
            const handleTeamUpdate = (data) => {
                setTeam(prevTeam => ({
                    ...prevTeam,
                    ...data
                }));
            };

            globalWebSocketManager.addEventListener('connection_status', handleConnectionStatus);
            globalWebSocketManager.addEventListener('team_state_update', handleTeamUpdate);

            await globalWebSocketManager.connect(teamId, true);
            setIsConnecting(false);

            return () => {
                globalWebSocketManager.removeEventListener('connection_status', handleConnectionStatus);
                globalWebSocketManager.removeEventListener('team_state_update', handleTeamUpdate);
                globalWebSocketManager.disconnect();
            };

        } catch (error) {
            console.error('Error initializing team:', error);
            setError(error.response?.data?.detail || error.message || 'Failed to load team information');
            setIsConnecting(false);
        }
    }, [teamId, navigate]);

    useEffect(() => {
        let cleanupFunction;
        initializeTeam().then((cleanup) => {
            cleanupFunction = cleanup;
        });
        return () => {
            if (typeof cleanupFunction === 'function') {
                cleanupFunction();
            }
        };
    }, [initializeTeam]);

    const copyTeamCode = async () => {
        try {
            await navigator.clipboard.writeText(team.code);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            setError('Failed to copy team code');
        }
    };

    const handleRoleSelect = async (role) => {
        if (!currentUser) return;

        try {
            setIsUpdatingRole(true);
            setError(null);

            const response = await api.post(`/team/${teamId}/update-role`, {
                role: role,
                user_id: currentUser._id
            });

            if (response.data) {
                setSelectedRole(role);
                if (globalWebSocketManager.isConnected()) {
                    globalWebSocketManager.send({
                        type: 'role_update',
                        data: {
                            teamId,
                            userId: currentUser._id,
                            role
                        }
                    });
                }
                await initializeTeam();
            }
        } catch (error) {
            console.error('Error updating role:', error);
            setError(error.response?.data?.detail || error.message || 'Failed to update role');
            setSelectedRole('');
        } finally {
            setIsUpdatingRole(false);
        }
    };

    const handleStartQuest = async () => {
        if (!team) return;

        try {
            const allHaveRoles = team.members.every(member => member.role);
            if (!allHaveRoles) {
                setError('All team members must select roles before starting');
                return;
            }

            const response = await api.post(`/team/${teamId}/start`);
            if (response.data) {
                if (globalWebSocketManager.isConnected()) {
                    globalWebSocketManager.send({
                        type: 'quest_started',
                        data: {
                            teamId,
                            questId: team.quest_id
                        }
                    });
                }
                // Небольшая задержка для обработки WebSocket сообщения
                setTimeout(() => {
                    navigate(`/quest/${team.quest_id}/step/1/substep/0`);
                }, 500);
            }
        } catch (error) {
            console.error('Error starting quest:', error);
            setError(error.response?.data?.detail || error.message || 'Failed to start quest');
        }
    };

    if (isConnecting || !team) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Card className="w-full max-w-md">
                    <CardContent className="p-6 text-center">
                        <Loader2 className="w-8 h-8 animate-spin mx-auto mb-4" />
                        <p>Connecting to team...</p>
                    </CardContent>
                </Card>
            </div>
        );
    }

    // Определяем, является ли текущий пользователь лидером команды
    const isLeader = currentUser && team.members.some(
        m => m.user_id === currentUser._id && m.is_leader
    );

    return (
        <div className="min-h-screen flex items-center justify-center p-4">
            <Card className="w-full max-w-2xl">
                <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                        <Users className="h-6 w-6" />
                        {team.name}
                    </CardTitle>
                    <CardDescription>
                        Waiting for team members to join and select roles
                    </CardDescription>
                </CardHeader>

                <CardContent className="space-y-6">
                    {error && (
                        <Alert variant="destructive">
                            <AlertCircle className="h-4 w-4" />
                            <AlertDescription>{String(error)}</AlertDescription>
                        </Alert>
                    )}

                    <div className="bg-background/95 p-4 rounded-lg border">
                        <div className="text-sm text-muted-foreground mb-2">
                            Share this team code:
                        </div>
                        <div className="flex items-center gap-2">
                            <code className="bg-background px-2 py-1 rounded text-lg font-mono">
                                {team.code}
                            </code>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={copyTeamCode}
                                className="h-8 px-2"
                            >
                                {copied ? (
                                    <CheckCircle className="h-4 w-4 text-green-500" />
                                ) : (
                                    <Copy className="h-4 w-4" />
                                )}
                            </Button>
                        </div>
                    </div>

                    {currentUser && !team.members.find(m => m.user_id === currentUser._id)?.role && (
                        <div className="space-y-2">
                            <h3 className="text-sm font-medium">Select Your Role:</h3>
                            <div className="grid grid-cols-3 gap-2">
                                {['Gamer', 'Listener', 'Regular'].map((role) => {
                                    const isDisabled = role === 'Gamer' &&
                                        team.members.filter(m => m.role === 'Gamer').length >= 2;

                                    return (
                                        <Button
                                            key={role}
                                            variant={selectedRole === role ? 'default' : 'outline'}
                                            onClick={() => handleRoleSelect(role)}
                                            disabled={isUpdatingRole || isDisabled}
                                            className="flex items-center gap-2"
                                        >
                                            <Shield className="h-4 w-4" />
                                            {role}
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <h3 className="font-semibold">
                                Team Members ({team.members.length}/{team.max_participants})
                            </h3>
                        </div>

                        <div className="space-y-2">
                            {team.members.map((member) => (
                                <div
                                    key={member.user_id}
                                    className="flex items-center justify-between p-3 bg-background/50 rounded-lg"
                                >
                                    <div className="flex items-center gap-2">
                                        {member.is_leader && (
                                            <Crown className="h-4 w-4 text-yellow-500" />
                                        )}
                                        <span className="font-medium">{member.username}</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        {member.role ? (
                                            <span
                                                className={`text-sm font-medium ${member.role === 'Gamer'
                                                    ? 'text-green-500'
                                                    : member.role === 'Listener'
                                                        ? 'text-blue-500'
                                                        : 'text-purple-500'
                                                    }`}
                                            >
                                                {member.role}
                                            </span>
                                        ) : (
                                            <span className="text-sm text-gray-400">
                                                Selecting role...
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {Array.from({ length: team.max_participants - team.members.length }).map((_, i) => (
                                <div
                                    key={`empty-${i}`}
                                    className="flex items-center justify-center p-3 bg-background/50 rounded-lg border-dashed border-2 border-muted"
                                >
                                    <UserPlus className="h-5 w-5 text-muted-foreground" />
                                </div>
                            ))}
                        </div>
                    </div>

                    {isLeader ? (
                        <Button
                            className="w-full"
                            onClick={handleStartQuest}
                            disabled={
                                team.members.length < team.max_participants ||
                                team.members.some(m => !m.role)
                            }
                        >
                            {team.members.length < team.max_participants ? (
                                <>
                                    <Clock className="mr-2 h-4 w-4" />
                                    Waiting for all members to join...
                                </>
                            ) : team.members.some(m => !m.role) ? (
                                <>
                                    <Clock className="mr-2 h-4 w-4" />
                                    Waiting for role selection...
                                </>
                            ) : (
                                <>
                                    <Info className="mr-2 h-4 w-4" />
                                    Start Quest
                                </>
                            )}
                        </Button>
                    ) : (
                        <Alert>
                            <AlertDescription>
                                Waiting for team leader to start the quest...
                            </AlertDescription>
                        </Alert>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default TeamWaitingRoom;

